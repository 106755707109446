import { RBAC_LINKS } from 'core/services/RBAC'
import { getRoleForAuth } from 'core/services/Storage'
import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Sidebar as SideBar, Nav, Sidenav } from 'rsuite'
import NavToggle from './NavToggle'
import clsx from 'clsx'
import Dashboard from 'shared/Icons/Dasboard'
import GamePad from 'shared/Icons/GamePad'
import Moderator from 'shared/Icons/Moderator'
import Trophy from 'shared/Icons/Trophy'
import Saturn from 'shared/Icons/Saturn'
import Wallet from 'shared/Icons/Wallet'
import UserAdd from 'shared/Icons/UserAdd'
import Battle from 'shared/Icons/Battle'
import ShopingCart from 'shared/Icons/ShopingCart'
import TeleVisionAds from 'shared/Icons/TeleVisionAds'
import Sliders from 'shared/Icons/Sliders'
import logo from 'assets/icons/battlexo-icon.png'
import { User } from 'core/models/User'
import Shorts from 'shared/Icons/Shorts'
import ThumbPin from 'shared/Icons/ThumbPin'
import CreatorIcon from 'shared/Icons/CreatorIcon'
import ChallengeIcon from 'shared/Icons/ChallengeIcon'

interface SidebarProps {
  user: User;
}

export default function Sidebar({ user }: SidebarProps) {

  const [expand, setExpand] = useState(true)
  const role = getRoleForAuth()

  useEffect(() => {
    if (window.innerWidth < 720) {
      setExpand(false)
    }
  }, [])

  const handleLinkClick = () => {
    if (window.innerWidth < 720 && expand) {
      setExpand(false)
    }
  }

  return (
    <SideBar
      width={expand ? 260 : 56}
      collapsible
      className={clsx('app-sidebar', !expand && 'collapsed')}
    >
      <Sidenav.Header className={clsx('sidebar-header', !expand && 'collapsed')}>
        <div className='sidebar-profile-container' >
          <div className="profile-image">
            <img
              src={user.media?.profileImg ?? logo}
              width={60}
              height={60}
              alt='Profile'
            />
          </div>
          <p className='user-name'>{user.name || user.userName || 'Hub User'}</p>
        </div>
      </Sidenav.Header>
      <Sidenav expanded={expand} defaultOpenKeys={[]} appearance="subtle" style={{ backgroundColor: '#6E6AF0' }}>
        <Sidenav.Body>
          <Nav className='nav-item-wrapper'>
            {
              RBAC_LINKS.dashboardRoutes.includes(role) &&
              <Nav.Item
                id='dashboard'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/dashboard"
                icon={<Dashboard fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Dashboard
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.gameRoute.includes(role) &&
              <Nav.Item
                id='games'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/games"
                icon={<GamePad fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Games
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.moderatorRoute.includes(role) &&
              <Nav.Item
                id='moderators'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/moderators"
                icon={<Moderator fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Moderators
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.tournamentRoutes.includes(role) &&
              <Nav.Item
                id='tournaments'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/tournaments"
                icon={<Trophy fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Tournaments
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.challengeRoutes.includes(role) &&
              <Nav.Item
                id='challenges'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/challenges"
                icon={<ChallengeIcon fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Challenges
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.creatorRoutes.includes(role) &&
              <Nav.Item
                id='creators'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/creators"
                icon={<CreatorIcon fontSize={20} color='#ffffff' className='nav-icon' />}
              >
                <p className="nav-content">
                  Creators
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.brandEnquiryRoutes.includes(role) &&
              <Nav.Item
                id='brand_enquiry'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/brand/enquiry"
                icon={<CreatorIcon fontSize={20} color='#ffffff' className='nav-icon' />}
              >
                <p className="nav-content">
                  Brand Enquiry
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.spaceRoutes.includes(role) &&
              <Nav.Item
                id='spaces'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/spaces"
                icon={<Saturn fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Spaces
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.rivalryZoneRoutes.includes(role) &&
              <Nav.Item
                id='rivalry_zone'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/rivalry-zone"
                icon={<Battle fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Rivalry Zone
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.walletRoutes.includes(role) &&
              <Nav.Item
                id='admin_wallet'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/admin-wallet"
                icon={<Wallet fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Wallet
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.walletRoutes.includes(role) &&
              <Nav.Item
                id='wallet'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/wallet"
                icon={<Wallet fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Manage Wallet
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.pushNotificationRoutes.includes(role) &&
              <Nav.Item
                id='push_notification'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/push-notification"
                icon={<UserAdd fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Push Notification
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.userAccessRoutes.includes(role) &&
              <Nav.Item
                id='user_access_request'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/user/access-request"
                icon={<UserAdd fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Queries
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.webSliderRoutes.includes(role) &&
              <Nav.Item
                id='slider'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/slider"
                icon={<Sliders fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Web Slider
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.xoShopRoutes.includes(role) &&
              <Nav.Item
                id='xo_shop'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/xo-shop"
                icon={<ShopingCart fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Shop
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.advertisementRoutes.includes(role) &&
              <Nav.Item
                id='advertisements'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/advertisements"
                icon={<TeleVisionAds fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Advertisements
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.shortsRoutes.includes(role) &&
              <Nav.Item
                id='shorts'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/shorts"
                icon={<Shorts fontSize={20} className='nav-icon shorts' />}
              >
                <p className="nav-content">
                  Bytes
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.pinnedRoutes.includes(role) &&
              <Nav.Item
                id='pinned'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/pinned"
                icon={<ThumbPin fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Pins
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.provisionalTournamentsRoute.includes(role) &&
              <Nav.Item
                id='provisional_tournaments'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/provisional-tournaments"
                icon={<Trophy fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Tournament Requests
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.gameStoreRoute.includes(role) &&
              <Nav.Item
                id='game_store'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/game-store"
                icon={<Trophy fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Game Store
                </p>
              </Nav.Item>
            }
            {
              RBAC_LINKS.gameStoreRoute.includes(role) &&
              <Nav.Item
                id='pro_pass_users'
                onSelect={handleLinkClick}
                as={NavLink}
                activeClassName="active"
                to="/pro-pass/users"
                icon={<Trophy fontSize={20} className='nav-icon' />}
              >
                <p className="nav-content">
                  Pro-Pass Users
                </p>
              </Nav.Item>
            }
          </Nav>
        </Sidenav.Body>
      </Sidenav>
      <NavToggle expand={expand} onChange={() => setExpand(!expand)} />
    </SideBar >
  );
}
