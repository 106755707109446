import { CREATOR_CLAIMS_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { CreatorClaims } from 'core/models/Creator'

const initialState: { claimRequests: CreatorClaims[], count: number, currentPage: number, isLoading: boolean } = {
  claimRequests: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    claimRequests: CreatorClaims[];
    count: number;
    currentPage: number;
  }
}

export default function creatorClaimListReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case CREATOR_CLAIMS_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case CREATOR_CLAIMS_LIST_ACTION.SUCCESS:
      const { claimRequests, count, currentPage } = action.payload;
      return { ...state, claimRequests, count, currentPage, isLoading: false }

    case CREATOR_CLAIMS_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}