import { CREATOR_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { Creators } from 'core/models/Creator'

const initialState: { creators: Creators[], count: number, currentPage: number, isLoading: boolean, isCreator: boolean } = {
  creators: [],
  isCreator: false,
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    creators: Creators[];
    count: number;
    currentPage: number;
    isCreator: boolean;
  }
}

export default function creatorListReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case CREATOR_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case CREATOR_LIST_ACTION.SUCCESS:
      const { creators, count, currentPage, isCreator } = action.payload;
      return { ...state, creators, count, currentPage, isLoading: false, isCreator }

    case CREATOR_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}