import { handleError } from "core/utils/HandleError"
import { handleResponse } from "core/utils/HandleResponse"
import { AuthHttpClient } from "core/utils/HttpClient"

export function getAccessRequests(params: any) {
  return AuthHttpClient
    .get('/admin/hub', { params })
    .then(handleResponse)
    .catch(handleError)
}

export function approveAccessRequest(requestId: number) {
  return AuthHttpClient
    .post(`/admin/hub?id=${requestId}`)
    .then(handleResponse)
    .catch(handleError)
}

export function getDashBoardDetails() {
  return AuthHttpClient
    .get('/admin/dashboard')
    .then(handleResponse)
    .catch(handleError)
}

export function getUserData() {
  return AuthHttpClient
    .get('/admin/me')
    .then(handleResponse)
    .catch(handleError)
}

export function verifyUserAccount(payload: any) {
  return AuthHttpClient
    .post('/admin/verify', payload)
    .then(handleResponse)
    .catch(handleError)
}

export function requestAccountVerify() {
  return AuthHttpClient
    .patch('/admin/request/verify', {})
    .then(handleResponse)
    .catch(handleError)
}

export function updateUserData(data: any) {
  return AuthHttpClient
    .put('/admin/me', data)
    .then(handleResponse)
    .catch(handleError)
}
export function getAccessGameRequests(params: any) {
  return AuthHttpClient
    .get('/admin/request/game', { params })
    .then(handleResponse)
    .catch(handleError)
}
export function requestFeature(message: string) {
  return AuthHttpClient
    .post('/admin/request/feature', { message })
    .then(handleResponse)
    .catch(handleError)
}
export function requestSponsor(file: any) {
  return AuthHttpClient
    .post('/admin/request/sponsor', file)
    .then(handleResponse)
    .catch(handleError)
}

export function getAccessGameStoreRequests(params: any) {
  return AuthHttpClient
    .get('/admin/request/game/store', { params })
    .then(handleResponse)
    .catch(handleError)
}

