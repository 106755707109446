import { SelectOptions } from 'core/models/Settings';
import { useCallback, useState } from 'react';
import { Button, Input, Modal, SelectPicker } from 'rsuite';
import { uploadSponsorMedia } from 'core/services/Upload'
import FileUploader from 'components/FileUploader';


interface RequestSponsorProps {
  show: boolean;
  onHide(): void;
  onCreate(data: any): void
}

const SponsorOptions: SelectOptions[] = [
  {
    label: 'Tournament',
    value: 'tournament'
  },
  {
    label: 'Space',
    value: 'space'
  },
  {
    label: 'Team',
    value: 'team'
  },
  {
    label: 'Others',
    value: 'others'
  }
]

export default function RequestSponsor({ show, onHide, onCreate }: RequestSponsorProps) {
  const [message, setMessage] = useState('')
  const [sponsorType, setSponsorType] = useState<SelectOptions | null>(null)
  const [selectedFile, setSelectedFile] = useState('');
  const [file, setFile] = useState<any>();
  const [attachMentError, setAttachError] = useState('');
  const [sponsorTypeError, setSponsorTypeError] = useState('');


  async function handleUpload(files: File[]) {
    const formData = new FormData()
    files.forEach(file => formData.append('pdf', file))
    const res = await uploadSponsorMedia(formData)
    if (res.status && res.data) {
      setSelectedFile(res.data.url)
    }
  }

  const validateForm = useCallback(() => {
    let isValid = true
    if (!selectedFile) {
      setAttachError('Attachment Required')
      isValid = false
    }
    if (!sponsorType) {
      setSponsorTypeError('Type Required')
      isValid = false
    }
    return isValid
  }, [selectedFile, sponsorType])


  function handleForm() {
    const isValid = validateForm()
    if (isValid) {
      const formdata: any = {}
      if (selectedFile) {
        formdata.fileLink = selectedFile
        setAttachError('')
      }
      if (message) {
        formdata.message = message.trim()
      }
      if (sponsorType) {
        formdata.type = sponsorType
        setSponsorTypeError('')
      }
      onCreate(formdata);
      handleClose()
    }
  }

  function handleSponsorType(value: any) {
    setSponsorType(value)
  }

  function handleClose() {
    onHide()
    setSponsorTypeError('')
    setAttachError('')
    setMessage('')
    setSponsorType(null)
    setSelectedFile('')
  }

  async function handleFileChange(files: File[]) {
    setFile(file[0])
    await handleUpload(file)
  }

  return <>
    <Modal open={show} onClose={handleClose} className='request-sponsor'>
      <Modal.Header>
        <Modal.Title>Request Sponsor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FileUploader
          className='attachment'
          onFileChange={handleFileChange}
          onRemove={() => setSelectedFile('')}
          value={selectedFile}
          label={file?.name}
          placeholder="Add attachment
          (only PDF)"
          accept='application/pdf'
          error={attachMentError}
        />
        <SelectPicker
          name="sponsor"
          value={sponsorType?.value}
          placeholder="Select Sponsor"
          data={SponsorOptions}
          searchable={false}
          onChange={handleSponsorType}
          className='select'
          block
          appearance="subtle"
        />
        {sponsorTypeError && <p className='error'>{sponsorTypeError}</p>}

        <Input
          as="textarea"
          onChange={setMessage}
          placeholder='Write a Message'
          className='message-input'
          value={message}
        />
        <Modal.Footer>
          <div className='btn-container'>
            <Button
              onClick={handleForm}
              appearance="primary">
              Add
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  </>
}
