import { CREATOR_BRAND_ENQUIRY_LIST } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { CreatorBrandEnquiries } from 'core/models/Creator'

const initialState: { brandEnquiries: CreatorBrandEnquiries[], count: number, currentPage: number, isLoading: boolean } = {
  brandEnquiries: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    brandEnquiries: CreatorBrandEnquiries[];
    count: number;
    currentPage: number;
  }
}

export default function creatorBrandEnquiryListReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case CREATOR_BRAND_ENQUIRY_LIST.REQUEST:
      return { ...state, isLoading: true }

    case CREATOR_BRAND_ENQUIRY_LIST.SUCCESS:
      const { brandEnquiries, count, currentPage } = action.payload;
      return { ...state, brandEnquiries, count, currentPage, isLoading: false }

    case CREATOR_BRAND_ENQUIRY_LIST.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}