import { SPACE_POLL_DETAILS_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { SpacePost } from 'core/models/Space'

const initialState: { spacePoll: SpacePost | null, isLoading: boolean } = {
  spacePoll: null,
  isLoading: false,
}

interface ActionType extends Action {
  payload: {
    spacePoll: SpacePost;
  }
}

export default function spacePollDetailsReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case SPACE_POLL_DETAILS_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case SPACE_POLL_DETAILS_ACTION.SUCCESS:
      const { spacePoll } = action.payload;
      return { ...state, spacePoll, isLoading: false }

    case SPACE_POLL_DETAILS_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}