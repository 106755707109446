import { SPACE_POLL_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { SpacePost } from 'core/models/Space'

const initialState: { spacePolls: SpacePost[], pollCount: number, pollCurrentPage: number, pollLoading: boolean } = {
  spacePolls: [],
  pollLoading: false,
  pollCount: 0,
  pollCurrentPage: 0
}

interface ActionType extends Action {
  payload: {
    spacePolls: SpacePost[];
    pollCount: number;
    pollCurrentPage: number;
  }
}

export default function spacePollListReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case SPACE_POLL_LIST_ACTION.REQUEST:
      return { ...state, pollLoading: true }

    case SPACE_POLL_LIST_ACTION.SUCCESS:
      const { spacePolls, pollCount, pollCurrentPage } = action.payload;
      return { ...state, spacePolls, pollCount, pollCurrentPage, pollLoading: false }

    case SPACE_POLL_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}